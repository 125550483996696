import React, { useState, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import CloseIcon from "@material-ui/icons/Close"

/* NPM FILES */
import * as validator from "validator"

/* LOCAL FILES */
import UseApi from "../hooks/useApi"
import Progress from "../components/Progress"
import Modal from "../components/Modal"
import { SEND_FORM_CONTACT } from "../constants/endpoints"

const useStyles = makeStyles({
  Containter: {
    "& div.MuiDrawer-paper": {
      backgroundColor: "transparent",
    },
  },
  list: {
    width: "973px",
    color: "white",
    textAlign: "rigth",
    backgroundColor: "#191919A6",
    opacity: 1,
    height: "100% !important",
    filter: "alpha(opacity100)",
    "& div.MuiDrawer-paper": {
      backgroundColor: "red",
    },
    "@media (max-width: 720px)": {
      width: "90%",
      marginLeft: "10%",
    },
  },
  fullList: {
    width: "auto",
    backgroundColor: "red",
  },
  Texto: {
    fontSize: "15px",
    font: "800 24px/15px Montserrat",
    width: "100%",
    textAlign: "right !important",
    marginLeft: "2rem",
    marginRight: "1rem",
  },
  title: {
    marginTop: "3rem",
    font: "800 45px/55px Montserrat",
    color: "#4267CB",
    "@media (max-width: 720px)": {
      font: "800 28px Montserrat",
    },
  },
  Subtitulo: {
    color: "white",
    marginTop: "1rem",
    font: "400 17px/30px Montserrat",
    "@media (max-width: 720px)": {
      font: "400 18px Montserrat",
    },
  },
  CloseIcon: {
    marginTop: "1rem",
    cursor: "pointer",
  },
  button: {
    justifyContent: "flex-end",
    alignItems: "flex-start",
    "@media (max-width: 720px)": {
      justifyContent: "center",
    },
  },
  TextInput: {
    marginTop: "3.5rem",
    "@media (max-width: 720px)": {
      marginTop: "1.5rem",
    },
    color: "white",
    "& label.Mui-focused": {
      color: "white",
    },
    "& ::label": {
      color: "white",
    },
    "&:focus": {
      borderColor: " white",
      transition: "border-color 0.3s ease-out",
    },
    "& .MuiInputBase-input:after": {
      borderBottomColor: "white",
    },
    "& .MuiInputBase-input:before": {
      borderBottomColor: "white",
    },
    "& ::placeholder": {
      color: "white !important",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiSelect-root": {
      color: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiInput-input": {
      color: "white",
    },
    "& .MuiTextField-root": {
      color: "white",
    },
  },
  marginInput: {
    marginLeft: "2.5rem",
  },
  TextMenu: {
    color: "white",
    font: "400 17px/20px Montserrat",
  },

  containerInputs: {
    "@media (max-width: 720px)": {
      marginTop: "-5rem",
    },
  },
})

const StyledButton = withStyles({
  root: {
    background:
      "transparent linear-gradient(90deg, #4267CB 0%, #16B1CB 100%) 0% 0% no-repeat padding-box",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "194px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
    "@media (max-width: 720px)": {
      height: 60,
      width: "240px",
      borderRadius: "50px",
      font: "800 18px Montserrat",
    },
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    font: "400 20px/24px Helvetica Neue",
  },
})(Button)

const StyledButtonSig = withStyles({
  root: {
    background: "linear-gradient(90deg, #CFF31B 0%, #16B1CB 100%)",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "194px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    fontSize: "27px",
    font: "800 22px/27px Montserrat",
  },
})(Button)

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "white",
    },
    "& label.MuiFormLabel-root ": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6184E5",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#6184E5",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6184E5",
      },
    },
  },
})(TextField)

export default function FormContact(props) {
  const classes = useStyles()
  const [state, setState] = useState({
    left: true,
  })

  const [ErroeEmail, setErroeEmail] = useState(false)
  const [ErrorTel, setErrorTel] = useState(false)
  const [DisabledButton, setDisabledButton] = useState(true)
  const [Nombre, setNombre] = useState("")
  const [Correo, setCorreo] = useState("")
  const [Telefono, setTelefono] = useState("")
  const [Empresa, setEmpresa] = useState("")
  const [Descripccion, setDescripccion] = useState("")
  const { response, post } = UseApi()
  const [showProgress, setShowProgress] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [MessageModal, setMessageModal] = useState("")

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  useEffect(() => {
    if (response.headerResponse) {
      setShowProgress(false)
      const { headerResponse } = response
      const { code } = headerResponse
      setMessageModal(headerResponse.message)
      setOpenModal(true)
    }
  }, [response])
  const ValidateButton = () => {
    if (
      ErroeEmail === true ||
      ErrorTel === true ||
      Nombre === "" ||
      Correo === "" ||
      Telefono === "" ||
      Empresa === "" ||
      Descripccion === "" ||
      Descripccion.length < 10
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }
  const HandleChangeName = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (/^([A-Z a-z])*$/.test(caracteravalidar) === true) {
      setNombre(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setNombre(sustrae)
    }
    ValidateButton()
  }
  const HandleChangeEmail = e => {
    setCorreo(e.target.value)
    setErroeEmail(!validator.isEmail(e.target.value))
    ValidateButton()
  }

  const HandleChangeTel = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (
      validator.isInt(caracteravalidar) === true &&
      e.target.value.length <= 10 &&
      e.target.value.length >= 0
    ) {
      if (e.target.value.length < 10) {
        setErrorTel(true)
      } else {
        setErrorTel(false)
      }
      setTelefono(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setTelefono(sustrae)
    }
    ValidateButton()
  }
  const HandleChangecompany = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (caracteravalidar) {
      setEmpresa(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setEmpresa(sustrae)
    }
    ValidateButton()
  }
  const HandleChangedescription = e => {
    setDescripccion(e.target.value)
    ValidateButton()
  }

  const handleClose = async () => {
    setNombre("")
    setCorreo("")
    setTelefono("")
    setEmpresa("")
    setDescripccion("")
    props.setOpenContact()
    setOpenModal(false)
  }

  const SendData = async () => {
    const query = {
      endpoint: SEND_FORM_CONTACT,
      data: {
        nombre: Nombre,
        telefono: Telefono,
        descripcion: Descripccion,
        empresa: Empresa,
        email: Correo,
      },
    }
    setShowProgress(true)
    await post(query)
  }
  const list = () => (
    <Grid container className={classes.list} role="presentation">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="baseline"
        xs={12}
      >
        <Grid
          xs={11}
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start"
        >
          <CloseIcon
            className={classes.CloseIcon}
            onClick={() =>
              props.open
                ? props.setOpenContact(false)
                : toggleDrawer("right", false)
            }
          />
        </Grid>
        <Grid xs={11} sm={10}>
          <Typography className={classes.title}>
            Déjanos un comentario
          </Typography>
          <Typography className={classes.Subtitulo}>
            Nos pondremos en contacto lo más pronto posible.
          </Typography>
        </Grid>
        <Grid container xs={11} sm={10} justify="space-between">
          <Grid xs={11} sm={5}>
            <CssTextField
              variant="outlined"
              className={classes.TextInput}
              label="Nombre"
              type="text"
              onChange={HandleChangeName}
              value={Nombre}
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
          <Grid xs={11} sm={5}>
            <CssTextField
              label="Correo electrónico"
              type="email"
              variant="outlined"
              className={classes.TextInput}
              onChange={HandleChangeEmail}
              value={Correo}
              error={ErroeEmail}
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
          <Grid xs={11} sm={5}>
            <CssTextField
              label="Teléfono"
              type="text"
              variant="outlined"
              className={classes.TextInput}
              value={Telefono}
              onChange={HandleChangeTel}
              error={ErrorTel}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid xs={11} sm={5}>
            <CssTextField
              label="Empresa"
              type="text"
              variant="outlined"
              className={classes.TextInput}
              onChange={HandleChangecompany}
              value={Empresa}
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
          <Grid xs={11} sm={12} className={classes.containerDescription}>
            <CssTextField
              label="Descripcción"
              type="text"
              variant="outlined"
              className={classes.TextInput}
              multiline
              rows={6}
              onChange={HandleChangedescription}
              value={Descripccion}
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
        </Grid>
        <Grid md={10} xs={12} style={{ padding: "2vh", textAlign: 'center' }}>
          <Typography>
            Déjanos saber que piensas enviando un correo electrónico. Solo da
            clic{" "} &nbsp;
            <a href="mailto:ventas@octopy.com" style={{ color: "red" }}>
              Aquí,
            </a>
          </Typography>
          <Typography style={{ textAlign: 'center' }}>
            O puedes llamar a nuestros números <a href="tel:5583547178" >5583547178</a> {" "} <a href="tel:5583995500" >5583995500</a>
          </Typography>
        </Grid>
        <Grid xs={10} container direction="row" className={classes.button}>
          <StyledButtonSig onClick={() => SendData()} disabled={DisabledButton}>
            Enviar
          </StyledButtonSig>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <div>
      <React.Fragment key={"right"}>
        <Progress show={showProgress}></Progress>
        <Modal message={MessageModal} open={openModal} setOpen={handleClose} />
        <Drawer
          anchor={"right"}
          open={props.open ? props.open : state["right"]}
          className={classes.Containter}
          onClose={() => props.setOpenContact()}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
